<template>
  <div
    style="
      min-height: 80vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 35vh;
    "
  >
    <loader v-if="showLoader" />
    <span
      style="
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.1em;
        color: rgba(18, 43, 62, 0.7);
      "
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
import Loader from "@/components/UI/Loader.vue";
import busService from '../../requests/Bus/busService';
export default {
  components: { Loader },
  data: () => ({
    showLoader: true,
    text: "",
  }),
  mounted() {
    this.getBusByQrCode();
  },
  methods: {
    async getBusByQrCode() {
      await busService
        .getBusByQrCode(this.$route.params.bus_id)
        .then((response) => {
          if (response.data.length > 0) {
            let route =
              response.data[0].departure_stations.length == 0 &&
              response.data.length > 1
                ? response.data[1]
                : response.data[0];
            let departureCity = route?.departure_stations[0];
            let arrivalCity = route?.arrival_stations[0];
            this.$router.push(
              `/routes/${departureCity?.city?.name}/${arrivalCity.city.name}/${route?.route_info?.slug}/1?openMenu=true`
            );
          } else {
            this.text = "Поки що автобус немає активних рейсів";
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          }
          this.showLoader = false;
        })
        .catch((e) => {
          console.log("e", e);
          this.showLoader = false;
          this.text = "На жаль такого автобуса не існує";
        });
    },
  },
};
</script>

<style>
</style>